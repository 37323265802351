import { render, staticRenderFns } from "./PositionList.vue?vue&type=template&id=f95efa42&scoped=true&"
import script from "./PositionList.vue?vue&type=script&lang=ts&"
export * from "./PositionList.vue?vue&type=script&lang=ts&"
import style0 from "./PositionList.vue?vue&type=style&index=0&id=f95efa42&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f95efa42",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VSimpleTable,VTooltip})
