
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class PriceColored extends Vue {
  @Prop()
  price?: number;

  @Prop({ default: 2 })
  precision?: number;

  @Prop()
  currency?: string;

  @Prop({ default: false })
  noColor?: boolean;

  priceNotChanged = "";
  priceChanged = "";

  color = "inherit";
  partialColoring = true;
  timeout?: number;

  @Watch("price", { immediate: true })
  onPriceChange(value: number, oldValue: number) {
    this.color = value > oldValue ? "rgb(3, 166, 109)" : value < oldValue ? "red" : "inherit";
    if (this.noColor) {
      this.color = "inherit";
    }
    //this.partialColoring = false;
    this.diff(value, oldValue);

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this.timeout = setTimeout(() => {
      //this.partialColoring = true;
      this.color = "inherit";
    }, 400);
  }

  private diff(value?: number, oldValue?: number) {
    if (oldValue === undefined || value === undefined) {
      this.priceNotChanged = value !== undefined ? this.format(value) : "";
      this.priceChanged = "";
      return;
    }

    const oldValueFormatted = this.format(oldValue);
    const valueFormatted = this.format(value);
    this.priceNotChanged = "";
    this.priceChanged = "";
    const oldValueChars = oldValueFormatted.split("");
    const valueChars = valueFormatted.split("");
    let pos = 0;
    for (const [index, char] of valueChars.entries()) {
      if (char !== oldValueChars[index]) {
        pos = index;
        break;
      }
      pos = index + 1;
    }
    this.priceNotChanged = valueFormatted.substring(0, pos);
    this.priceChanged = valueFormatted.substring(pos);
  }

  private format(price: number): string {
    return price.toFixed(this.precision);
  }
}
