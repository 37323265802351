
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PositionHandler, { MarketPosition } from "@/components/Balance/positionHandler";
import MathUtils from "@/service/MathUtils";
import { mdiClose, mdiMace, mdiRefresh } from "@mdi/js";

@Component
export default class PositionCalculator extends Vue {
  @Prop()
  position?: MarketPosition;

  @Prop()
  positionHandler?: PositionHandler;

  open = false;

  icons = {
    mdiRefresh: mdiRefresh,
    mdiMace: mdiMace,
    mdiClose: mdiClose,
  };

  onPercentChanged() {
    if (!this.positionHandler || !this.position) {
      return;
    }

    const currentPriceChanged = this.position.priceChange.originalValue * (1 + this.position.priceChange.percent / 100);
    const lastPrice = MathUtils.round(currentPriceChanged, this.position?.quotePrecision);

    this.position.priceChange.enabled = true;
    this.positionHandler.calculatePositionThrottled(this.position, lastPrice);
  }

  async reset() {
    if (!this.positionHandler || !this.position) {
      return;
    }

    this.positionHandler.resetPositionCalculator(this.position).then();
  }
}
