
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PriceColored2 from "@/components/Balance/PriceColored2.vue";
import { CombinedPrice } from "@/components/Balance/marketPositionTotal";

@Component({
  components: { PriceColored2 },
})
export default class PositionCalculator extends Vue {
  @Prop()
  combinedPrice?: CombinedPrice;

  @Prop()
  label?: string;

  @Prop()
  showHuf?: boolean;
}
