//@ts-ignore
import { Context, Price } from "oanda-v20-javascript/src/context";
//@ts-ignore
import { ClientRequest } from "http";

export default class OandaUpdates {
  private context: Context;
  private host = "stream-fxtrade.oanda.com";
  private token = "d03984624744425f56159782578d466a-b87a98406f1251013c673920746215e1";
  private accountId = "001-004-3971703-001";
  private request?: ClientRequest;

  init() {
    this.context = new Context(this.host, "443", true);
    this.context.setToken(this.token);
  }

  streamPrice(instruments: string[], handler: (instrument: string, price: number) => void): ClientRequest {
    this.request = this.context.pricing.stream(
      this.accountId,
      { instruments: instruments },
      (price: Price) => {
        if (price.type === "PRICE") {
          const middlePrice = (parseFloat(price.closeoutAsk) + parseFloat(price.closeoutBid)) / 2;
          handler(price.instrument, middlePrice);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }

  destroy() {
    if (this.request) {
      this.request.abort();
    }
  }
}
