
import Vue from "vue";
import Component from "vue-class-component";
import { MarketPositionTotal } from "@/components/Balance/marketPositionTotal";
import TotalPrice from "@/components/Balance/TotalPrice.vue";
import { Prop } from "vue-property-decorator";
import { mdiEqual, mdiPlus } from "@mdi/js";
import TotalPriceMobile from "@/components/Balance/TotalPriceMobile.vue";

@Component({
  components: { TotalPriceMobile, TotalPrice },
})
export default class BottomBalanceMobile extends Vue {
  @Prop()
  total?: MarketPositionTotal;

  @Prop()
  showHuf?: boolean;

  open = false;

  icons = {
    mdiPlus: mdiPlus,
    mdiEqual: mdiEqual,
  };

  touchUp() {
    this.open = true;
  }
}
