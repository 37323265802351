import { render, staticRenderFns } from "./OrderListMobile.vue?vue&type=template&id=7702c4cc&scoped=true&"
import script from "./OrderListMobile.vue?vue&type=script&lang=ts&"
export * from "./OrderListMobile.vue?vue&type=script&lang=ts&"
import style0 from "./OrderListMobile.vue?vue&type=style&index=0&id=7702c4cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7702c4cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
