import { io, Socket } from "socket.io-client";
import OhlcvCandle from "@/model/OhlcvCandle";

type SocketListener = (candle: OhlcvCandle) => void;
type SocketListenerInternal = (arrayBuffer: ArrayBuffer) => void;

export default class LiveUpdates {
  private socket: Socket;
  private roomInfos: Array<{ room: string; socketListener: SocketListenerInternal }> = [];

  constructor() {
    this.socket = io();
    this.socket.on("connect", this.connectHandler);
  }

  reset() {
    if (this.socket.connected) {
      this.leaveRooms();
    }
    this.roomInfos = [];
  }

  addRoom(room: string, socketListener: SocketListener) {
    this.roomInfos.push({ room: room, socketListener: this.createSocketListener(socketListener) });
  }

  join() {
    if (this.socket.connected) {
      this.joinRooms();
    }
  }

  destroy() {
    this.leaveRooms();
    this.socket.disconnect();
    this.socket.off("connect", this.connectHandler);
  }

  //Note: We need to use the same handler for "on" and "off" events. It needs to be in this format, so "this" means this class.
  private connectHandler = () => {
    this.joinRooms();
  };

  private joinRooms() {
    for (const roomInfo of this.roomInfos) {
      this.socket.emit("join", roomInfo.room);
      this.socket.on(roomInfo.room, roomInfo.socketListener);
    }
  }

  private leaveRooms() {
    for (const roomInfo of this.roomInfos) {
      this.socket.emit("leave", roomInfo.room);
      this.socket.off(roomInfo.room, roomInfo.socketListener);
    }
  }

  private createSocketListener(socketListener: SocketListener) {
    return (arrayBuffer: ArrayBuffer) => {
      const candle = OhlcvCandle.createFromArrayBuffer(arrayBuffer);
      socketListener(candle);
    };
  }
}
