
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import icons from "@/components/General/icons.json";

@Component
export default class CryptoiconCustom extends Vue {
  @Prop()
  currency?: string;

  @Prop({ default: 24 })
  size?: string | number;

  url: string | null = null;

  @Watch("currency", { immediate: true })
  getUrl(currency: string) {
    if (currency === "LUNC") {
      currency = "LUNA";
    }
    //@ts-ignore
    const filename = icons[currency];
    this.url = filename ? "https://raw.githubusercontent.com/dav1d8/cryptocurrency-icons/master/128/" + filename : "";
  }
}
