import { MarketPosition } from "@/components/Balance/positionHandler";
import { Balance, stableCoins } from "@/components/Balance/balanceHandler";

export class Price {
  value?: number;
  private formatter?: Intl.NumberFormat;

  constructor(public currency = "", public precision?: number) {
    const minimumFractionDigits = this.precision ?? 0;
    const maximumFractionDigits = this.precision ?? 8;
    this.setFormatter(minimumFractionDigits, maximumFractionDigits);
  }

  format(value?: number) {
    if (value === undefined) {
      value = this.value;
    }
    if (value === undefined) {
      return "";
    }

    this.checkFormatter(value);

    return this.formatter?.format(value);
  }

  private checkFormatter(value: number) {
    if (!this.formatter) {
      return;
    }

    //NOTE: We make sure that the formatted fraction does not shrink in the next update
    const formatOptions = this.formatter.resolvedOptions();

    const parts = this.formatter.formatToParts(value);
    const lastPart = parts[parts.length - 1];
    if (lastPart.type === "fraction" && lastPart.value.length > formatOptions.minimumFractionDigits) {
      //NOTE: Here we override minimumFractionDigits of the formatter
      this.setFormatter(lastPart.value.length, formatOptions.maximumFractionDigits);
    }
  }

  private setFormatter(minimumFractionDigits: number, maximumFractionDigits: number) {
    this.formatter = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    });
  }
}

export class CombinedPrice {
  price: Price;
  priceInHuf: Price;
  percent: Price;

  constructor(currency = "") {
    this.price = new Price(currency, 2);
    this.priceInHuf = new Price("HUF", 0);
    this.percent = new Price("%", 2);
  }
}

export class MarketPositionTotal {
  available: CombinedPrice;
  totalCost: CombinedPrice;
  currentValue: CombinedPrice;
  profitAndLoss: CombinedPrice;

  constructor(private positions: MarketPosition[], private balances: Balance[]) {
    const dollarCurrency = "USD";
    this.available = new CombinedPrice(dollarCurrency);
    this.totalCost = new CombinedPrice(dollarCurrency);
    this.currentValue = new CombinedPrice(dollarCurrency);
    this.profitAndLoss = new CombinedPrice(dollarCurrency);

    this.calculatePrices();
  }

  calculatePrices() {
    this.available.price.value = 0;
    this.totalCost.price.value = 0;
    this.currentValue.price.value = 0;
    this.profitAndLoss.price.value = 0;
    this.profitAndLoss.percent.value = 0;

    for (const position of this.positions) {
      if (!stableCoins.includes(position.quoteCurrency)) {
        const message = "Position is skipped from total, quote currency is not a stablecoin.";
        console.log(message, position.quoteCurrency);
        continue;
      }
      this.totalCost.price.value += position.totalCost;
      this.currentValue.price.value += position.currentValue;
      this.profitAndLoss.price.value += position.profitAndLoss;
    }

    for (const balance of this.balances) {
      if (stableCoins.includes(balance.currency) && balance.usdValue.value !== undefined) {
        this.available.price.value += balance.usdValue.value;
      }
    }

    //NOTE: We need to calculate this before we add "available" to "currentValue" below
    if (this.totalCost.price.value !== 0) {
      this.profitAndLoss.percent.value =
        ((this.currentValue.price.value - this.totalCost.price.value) / this.totalCost.price.value) * 100;
    }

    this.currentValue.price.value += this.available.price.value;

    this.calculateHufPrices();
  }

  calculateHufPrices() {
    this.available.priceInHuf.value = 0;
    this.totalCost.priceInHuf.value = 0;
    this.currentValue.priceInHuf.value = 0;
    this.profitAndLoss.priceInHuf.value = 0;

    for (const position of this.positions) {
      this.totalCost.priceInHuf.value += position.totalCostInHUF;
      this.currentValue.priceInHuf.value += position.currentValueInHUF;
      this.profitAndLoss.priceInHuf.value += position.profitAndLossInHUF;
    }

    for (const balance of this.balances) {
      if (stableCoins.includes(balance.currency) && balance.hufValue.value !== undefined) {
        this.available.priceInHuf.value += balance.hufValue.value;
      }
    }

    this.currentValue.priceInHuf.value += this.available.priceInHuf.value;
  }
}
