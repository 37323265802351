
import Vue from "vue";
import Component from "vue-class-component";
import { MarketPositionTotal } from "@/components/Balance/marketPositionTotal";
import TotalPrice from "@/components/Balance/TotalPrice.vue";
import { Prop } from "vue-property-decorator";
import { mdiEqual, mdiPlus } from "@mdi/js";

@Component({
  components: { TotalPrice },
})
export default class BottomBalance extends Vue {
  @Prop()
  total?: MarketPositionTotal;

  @Prop()
  showHuf?: boolean;

  icons = {
    mdiPlus: mdiPlus,
    mdiEqual: mdiEqual,
  };
}
