import CcxtPrivate from "@/service/CcxtPrivate";
import { Dictionary, Ticker } from "ccxt";
import { Price } from "@/components/Balance/marketPositionTotal";

export type Balance = {
  currency: string;
  usdSymbol: string;
  amount: Price;
  currentPrice: Price;
  usdValue: Price;
  hufValue: Price;
};

type CcxtBalances = {
  free: Record<string, number>;
  total: Record<string, number>;
  used: Record<string, number>;
};

//Tether, USD Coin, Binance USD, Dai, TrueUSD, Pax Dollar
export const stableCoins = ["USDT", "USDC", "BUSD", "DAI", "TUSD", "USDP"];

export default class BalanceHandler {
  private balances: Array<Balance> = [];

  constructor(private ccxtPrivate: CcxtPrivate) {}

  async getBalances(tickers$: Promise<Dictionary<Ticker>>) {
    //@ts-ignore
    const ccxtBalances: CcxtBalances = await this.ccxtPrivate.fetchBalance();
    const tickers = await tickers$;

    this.balances = [];
    for (const [currency, amountValue] of Object.entries(ccxtBalances.free)) {
      if (!(amountValue > 0)) {
        continue;
      }

      const amount = new Price(currency);
      const currentPrice = new Price("USD");
      const usdValue = new Price("USD", 2);
      const hufValue = new Price("HUF", 0);

      //Used to get the rate and calculate the usd value
      let usdSymbol = currency + "/USDT";
      if (currency === "LUNA") {
        usdSymbol = currency + "/BUSD";
      }

      if (stableCoins.includes(currency)) {
        currentPrice.value = 1;
      } else {
        const ticker = tickers[usdSymbol];
        currentPrice.value = ticker?.last;
      }
      usdValue.value = currentPrice.value !== undefined ? amountValue * currentPrice.value : undefined;
      amount.value = amountValue;

      this.balances.push({ currency, usdSymbol, amount, currentPrice, usdValue, hufValue });
    }

    return this.balances;
  }

  updateRate(symbol: string, rate: number) {
    for (const balance of this.balances) {
      if (balance.usdSymbol === symbol) {
        const amountValue = balance.amount.value;
        if (amountValue) {
          balance.currentPrice.value = rate;
          balance.usdValue.value = amountValue * rate;
        }
      }
    }
  }

  updateRateHuf(rate: number) {
    for (const balance of this.balances) {
      balance.hufValue.value = balance.usdValue.value !== undefined ? balance.usdValue.value * rate : undefined;
    }
  }
}
