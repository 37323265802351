
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Balance } from "@/components/Balance/balanceHandler";
import CryptoiconCustom from "@/components/General/CryptoiconCustom.vue";
import PriceColored2 from "@/components/Balance/PriceColored2.vue";
import PriceSpan from "@/components/Balance/PriceSpan.vue";
@Component({
  components: { PriceSpan, PriceColored2, CryptoiconCustom },
})
export default class BalanceList extends Vue {
  @Prop()
  balances?: Balance[];
}
