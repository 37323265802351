
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Price } from "@/components/Balance/marketPositionTotal";

@Component
export default class PriceSpan extends Vue {
  @Prop()
  price?: Price;

  @Prop({ default: false })
  hideCurrency?: boolean;

  get separator() {
    return this.price?.currency === undefined || this.price?.currency === "%" ? "" : " ";
  }
}
