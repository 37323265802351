
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PositionHandler, { CcxtOrder, MarketPosition } from "@/components/Balance/positionHandler";
import { orderBy } from "lodash-es";
import { mdiAlertOutline } from "@mdi/js";

@Component
export default class OrderList extends Vue {
  @Prop()
  position?: MarketPosition;

  @Prop()
  positionHandler?: PositionHandler;

  icons = {
    mdiAlertOutline: mdiAlertOutline,
  };

  get orders() {
    return orderBy(this.position?.orders, ["datetime"], ["desc"]);
  }

  private removeFakeOrder(order: CcxtOrder) {
    if (!this.position) {
      return;
    }

    if (order.fake) {
      this.positionHandler?.removeFakeOrder(this.position, order);
    }
  }

  formatDate(dateStr: string) {
    const date = new Date(dateStr);
    return date.toDateString() + "<br>" + date.toLocaleTimeString();
  }
}
