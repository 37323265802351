
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import PositionHandler, { CcxtOrder, MarketPosition } from "@/components/Balance/positionHandler";
import { mdiCheck, mdiClose, mdiMace } from "@mdi/js";

@Component
export default class FakeOrder extends Vue {
  @Prop()
  position?: MarketPosition;

  @Prop()
  positionHandler?: PositionHandler;

  open = false;

  icons = {
    mdiCheck: mdiCheck,
    mdiMace: mdiMace,
    mdiClose: mdiClose,
  };

  //@ts-ignore
  fakeOrder: CcxtOrder = {};

  @Watch("fakeOrder.cost")
  @Watch("fakeOrder.price")
  calculateAmount() {
    this.fakeOrder.amount =
      this.fakeOrder.price > 0 && this.fakeOrder.cost > 0 ? this.fakeOrder.cost / this.fakeOrder.price : 0;
  }

  @Watch("position")
  onPositionChanged() {
    this.initFakeOrder();
  }

  created() {
    this.initFakeOrder();
  }

  initFakeOrder() {
    const side = this.fakeOrder.side ?? "buy";
    const cost = this.fakeOrder.cost ?? 1000;
    //@ts-ignore
    this.fakeOrder = { fake: true, side: side, trades: [], fees: {}, cost: cost, price: this.position.currentPrice };
  }

  apply() {
    if (!this.position || !this.positionHandler) {
      return;
    }

    const newFakeOrder = { ...this.fakeOrder };
    this.positionHandler.addFakeOrder(this.position, this.fakeOrder);
    this.fakeOrder = newFakeOrder;
  }

  reset() {
    this.initFakeOrder();
  }
}
