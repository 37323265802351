
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CryptoiconCustom from "@/components/General/CryptoiconCustom.vue";
@Component({
  components: { CryptoiconCustom },
})
export default class CryptoiconCombined extends Vue {
  @Prop()
  baseCurrency?: string;

  @Prop()
  quoteCurrency?: string;

  @Prop({ default: 24 })
  size?: string | number;

  quoteSize = 0;

  hostStyle = {};
  styleBaseCurrency = {};
  styleQuoteCurrency = {};

  @Watch("size", { immediate: true })
  onSizeChanged() {
    const size = typeof this.size === "string" ? parseInt(this.size ?? 24) : this.size ?? 24;
    this.hostStyle = { height: size + "px" };
    this.quoteSize = Math.floor(size * 0.6);
    const quoteOffset = Math.floor(size * 0.8);

    this.styleBaseCurrency = {
      marginLeft: -1 * (this.quoteSize + quoteOffset) + "px",
      marginRight: quoteOffset + this.quoteSize - size + "px",
    };
    this.styleQuoteCurrency = { marginLeft: quoteOffset + "px" };
  }
}
