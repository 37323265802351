import { Order as CcxtOrderBase, Dictionary, Ticker } from "ccxt";
import MathUtils from "@/service/MathUtils";
import { throttle } from "lodash-es";
import { mergeMap, of, switchMap, toArray } from "rxjs";
import CcxtPrivate from "@/service/CcxtPrivate";

type PriceChange = {
  enabled: boolean;
  percent: number;
  originalValue: number;
};

export interface CcxtOrder extends CcxtOrderBase {
  fees: Dictionary<number>;
  fake: boolean;
}

export type MarketPosition = {
  symbol: string;
  baseCurrency: string;
  quoteCurrency: string;
  basePrecision: number;
  quotePrecision: number;
  totalCost: number;
  totalCostInHUF: number;
  amountSum: number;
  averagePrice: number;
  averagePricePercent: number;
  currentPrice: number;
  currentValue: number;
  currentValueInHUF: number;
  profitAndLoss: number;
  profitAndLossPercent: number;
  profitAndLossInHUF: number;
  feeInBase: number;
  feeInQuote: number;
  feeInBNB: number;
  orders: CcxtOrder[];
  market: Market;
  priceChange: PriceChange;
  rateHUF: number;
  hasFakeOrder: boolean;
};

type Market = {
  symbol: string;
  orders: CcxtOrder[];
  ticker?: Ticker;
};

export default class PositionHandler {
  constructor(private ccxtPrivate: CcxtPrivate) {}

  async getMarkets(symbols: string[], dateFrom: string, tickers$: Promise<Dictionary<Ticker>>) {
    return of(...symbols)
      .pipe(
        mergeMap((symbol) => {
          return this.createMarket(symbol, dateFrom);
        }, 4),
        toArray(),
        switchMap((markets) => {
          return tickers$.then((tickers) => {
            this.updateMarkets(markets, tickers);
            return markets;
          });
        })
      )
      .toPromise();
  }

  private updateMarkets(markets: Market[], tickers: Dictionary<Ticker>) {
    for (const market of markets) {
      const symbol = market.symbol;

      const ticker = tickers[symbol];
      if (!ticker) {
        console.error("MarketId or ticker not found for", symbol);
      }
      market.ticker = ticker;
    }
  }

  private async createMarket(symbol: string, dateFrom: string) {
    if (symbol === "LUNA/USDT") {
      dateFrom = "2022-05-27";
    }
    let querySymbol = symbol;
    if (symbol === "LUNC/BUSD") {
      querySymbol = "LUNA/USDT";
    }
    const since = new Date(dateFrom).getTime();

    const trades$ = this.ccxtPrivate.fetchMyTrades(querySymbol, since);
    //@ts-ignore
    const orders$: Promise<CcxtOrder[]> = this.ccxtPrivate.fetchOrders(querySymbol, since);

    const [trades, orders] = await Promise.all([trades$, orders$]);

    const visibleOrders = [];
    for (const order of orders) {
      if (order.status !== "closed") {
        continue;
      }
      for (const trade of trades) {
        if (trade.order === order.id) {
          order.trades.push(trade);
        }
      }
      order.fees = {};
      order.fake = false;
      visibleOrders.push(order);
    }

    const market: Market = {
      symbol: symbol,
      orders: visibleOrders,
      ticker: undefined,
    };
    return market;
  }

  async createPositions(markets: Market[]) {
    const positions = [];

    for (const market of markets) {
      const ticker = market.ticker;
      const currentPrice = ticker?.last ?? 0;
      const position = this.createPosition(market, currentPrice);
      positions.push(position);
    }

    return positions;
  }

  private createPosition(market: Market, currentPrice: number) {
    const symbol = market.symbol;
    const [baseCurrency, quoteCurrency] = symbol.split("/");
    //@ts-ignore
    const position: MarketPosition = {
      symbol: market.symbol,
      baseCurrency: baseCurrency,
      quoteCurrency: quoteCurrency,
      orders: market.orders,
      currentPrice: currentPrice,
      rateHUF: 370,
      hasFakeOrder: false,
      priceChange: {
        enabled: false,
        originalValue: currentPrice,
        percent: 0,
      },
    };
    this.updatePosition(position);
    return position;
  }

  updatePosition(position: MarketPosition) {
    let totalCost = 0;
    let amountSum = 0;
    let feeInBase = 0;
    let feeInQuote = 0;
    let feeInBNB = 0;
    for (const order of position.orders) {
      order.price = MathUtils.round(order.price, 8);
      order.amount = MathUtils.round(order.amount, 8);

      if (order.side === "buy") {
        totalCost += order.cost;
        amountSum += order.amount;
      } else {
        totalCost -= order.cost;
        amountSum -= order.amount;
      }
      order.fees = {};
      for (const trade of order.trades) {
        const feeCurrency = trade.fee.currency;
        if (!order.fees[feeCurrency]) {
          order.fees[feeCurrency] = trade.fee.cost;
        } else {
          order.fees[feeCurrency] += trade.fee.cost;
        }
        switch (trade.fee.currency) {
          case position.baseCurrency:
            feeInBase += trade.fee.cost;
            break;
          case position.quoteCurrency:
            feeInQuote += trade.fee.cost;
            break;
          case "BNB":
            feeInBNB += trade.fee.cost;
            break;
          default:
            throw "Fee currency is wrong.";
        }
      }
    }
    amountSum -= feeInBase;
    totalCost += feeInQuote;

    const basePrecision = 8;
    const feePrecision = 8;
    let quotePrecision = 8;
    if (position.quoteCurrency === "USDT" || position.quoteCurrency === "EUR") {
      quotePrecision = 2;
    }
    for (const order of position.orders) {
      for (const [feeCurrency, feeCost] of Object.entries(order.fees)) {
        order.fees[feeCurrency] = MathUtils.round(feeCost, feePrecision);
      }
    }
    const averagePrice = amountSum > 0 ? MathUtils.round(totalCost / amountSum, quotePrecision) : 0;
    const currentValue = position.currentPrice ? MathUtils.round(amountSum * position.currentPrice, quotePrecision) : 0;
    const averagePricePercent = MathUtils.round((averagePrice / position.currentPrice - 1) * 100, quotePrecision);
    const profitAndLoss = MathUtils.round(currentValue - totalCost, quotePrecision);
    const profitAndLossPercent =
      totalCost !== 0 ? MathUtils.round(((currentValue - totalCost) / totalCost) * 100, 2) : 0;
    totalCost = MathUtils.round(totalCost, quotePrecision);
    amountSum = MathUtils.round(amountSum, basePrecision);

    position.totalCost = totalCost;
    position.amountSum = amountSum;
    position.averagePrice = averagePrice;
    position.averagePricePercent = averagePricePercent;
    position.currentValue = currentValue;
    position.profitAndLoss = profitAndLoss;
    position.profitAndLossPercent = profitAndLossPercent;
    position.feeInBase = feeInBase;
    position.feeInQuote = feeInQuote;
    position.feeInBNB = feeInBNB;
    position.basePrecision = basePrecision;
    position.quotePrecision = quotePrecision;
    this.calculateHUFValues(position);
  }

  calculatePositionThrottled = throttle(this.calculatePosition, 100, { leading: true, trailing: true });

  async resetPositionCalculator(position: MarketPosition) {
    const lastPrice = await this.fetchLastPrice(position);
    position.priceChange = {
      enabled: false,
      originalValue: lastPrice,
      percent: 0,
    };

    this.calculatePosition(position, lastPrice);
  }

  calculatePosition(position: MarketPosition, lastPrice: number) {
    position.currentValue = MathUtils.round(position.amountSum * lastPrice, position.quotePrecision);
    position.profitAndLoss = MathUtils.round(position.currentValue - position.totalCost, position.quotePrecision);
    position.profitAndLossPercent =
      position.totalCost !== 0
        ? MathUtils.round(((position.currentValue - position.totalCost) / position.totalCost) * 100, 2)
        : 0;
    position.currentPrice = lastPrice;
    position.averagePricePercent = MathUtils.round(
      (position.averagePrice / position.currentPrice - 1) * 100,
      position.quotePrecision
    );

    this.calculateHUFValues(position);
  }

  async fetchLastPrice(position: MarketPosition) {
    const ticker = await this.ccxtPrivate.fetchTicker(position.symbol);
    return ticker.last ? ticker.last : 0;
  }

  updateRateHuf(position: MarketPosition, rateHUF: number) {
    position.rateHUF = rateHUF;
    this.calculateHUFValues(position);
  }

  private calculateHUFValues(position: MarketPosition) {
    position.totalCostInHUF = Math.round(position.totalCost * position.rateHUF);
    position.currentValueInHUF = Math.round(position.currentValue * position.rateHUF);
    position.profitAndLossInHUF = Math.round(position.profitAndLoss * position.rateHUF);
  }

  //
  // FAKE ORDER
  //
  addFakeOrder(position: MarketPosition, fakeOrder: CcxtOrder) {
    if (!(fakeOrder.amount > 0)) {
      return;
    }

    fakeOrder.datetime = new Date().toISOString();
    const feeRate = 0.001;
    if (fakeOrder.side === "buy") {
      fakeOrder.trades.push({
        //@ts-ignore
        fee: { cost: fakeOrder.amount * feeRate, currency: position.baseCurrency },
      });
    } else {
      fakeOrder.trades.push({
        //@ts-ignore
        fee: { cost: fakeOrder.cost * feeRate, currency: position.quoteCurrency },
      });
    }

    position.orders.push(fakeOrder);
    position.hasFakeOrder = true;
    this.updatePosition(position);
  }

  removeFakeOrder(position: MarketPosition, fakeOrder: CcxtOrder) {
    let hasFakeOrder = false;
    position.orders = position.orders.filter((order) => {
      if (order.fake && order !== fakeOrder) {
        hasFakeOrder = true;
      }
      return order !== fakeOrder;
    });
    position.hasFakeOrder = hasFakeOrder;
    this.updatePosition(position);
  }

  removeAllFakeOrder(position: MarketPosition) {
    position.orders = position.orders.filter((order) => {
      return !order.fake;
    });
    position.hasFakeOrder = false;
    this.updatePosition(position);
  }
}
