
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PriceColored from "@/components/Balance/PriceColored.vue";
import PositionHandler, { MarketPosition } from "@/components/Balance/positionHandler";
import { mdiAlert, mdiAlertOutline } from "@mdi/js";
import CryptoiconCombined from "@/components/General/CryptoiconCombined.vue";
import TotalPrice from "@/components/Balance/TotalPrice.vue";
import PriceWithHuf from "@/components/Balance/PriceWithHuf.vue";

@Component({
  components: { PriceWithHuf, TotalPrice, CryptoiconCombined, PriceColored },
})
export default class PositionList extends Vue {
  @Prop()
  positions?: MarketPosition[];

  @Prop()
  positionHandler?: PositionHandler;

  @Prop()
  selectedPosition?: MarketPosition;

  @Prop()
  showHuf?: boolean;

  icons = {
    mdiAlert: mdiAlert,
    mdiAlertOutline: mdiAlertOutline,
  };

  private onClickPosition(position: MarketPosition) {
    this.$emit("update:selectedPosition", position);
  }

  private getBackgroundColor(position: MarketPosition) {
    return this.selectedPosition && position.symbol === this.selectedPosition.symbol ? "#e5e5e5" : "";
  }

  private resetPositionCalculator(position: MarketPosition) {
    if (!this.positionHandler) {
      return;
    }

    this.positionHandler.resetPositionCalculator(position);
  }

  private removeAllFakeOrder(position: MarketPosition) {
    if (!this.positionHandler) {
      return;
    }

    this.positionHandler.removeAllFakeOrder(position);
  }
}
