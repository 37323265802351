
import Vue from "vue";
import Component from "vue-class-component";
import OhlcvCandle from "@/model/OhlcvCandle";
import PositionCalculator from "@/components/Balance/PositionCalculator.vue";
import OrderList from "@/components/Balance/OrderList.vue";
import PositionList from "@/components/Balance/PositionList.vue";
import LiveUpdates from "@/components/Balance/liveUpdates";
import PositionHandler, { MarketPosition } from "@/components/Balance/positionHandler";
import OandaUpdates from "@/components/Balance/oandaUpdates";
import FakeOrder from "@/components/Balance/FakeOrder.vue";
import { MarketPositionTotal } from "@/components/Balance/marketPositionTotal";
import TotalPrice from "@/components/Balance/TotalPrice.vue";
import BottomBalance from "@/components/Balance/BottomBalance.vue";
import BalanceList from "@/components/Balance/BalanceList.vue";
import CcxtPrivate from "@/service/CcxtPrivate";
import BalanceHandler, { Balance } from "@/components/Balance/balanceHandler";
import { marketApi } from "@/plugins/api";
import BottomBalanceMobile from "@/components/Balance/BottomBalanceMobile.vue";
import { orderBy } from "lodash-es";
import BalanceListMobile from "@/components/Balance/BalanceListMobile.vue";
import { lastValueFrom } from "rxjs";
import PositionListMobile from "@/components/Balance/PositionListMobile.vue";
import { mdiAlert } from "@mdi/js";

@Component({
  components: {
    PositionListMobile,
    BalanceListMobile,
    BottomBalanceMobile,
    BalanceList,
    BottomBalance,
    TotalPrice,
    FakeOrder,
    OrderList,
    PositionList,
    PositionCalculator,
  },
})
export default class ExchangeBalances extends Vue {
  private positions: Array<MarketPosition> = [];
  private balances: Balance[] = [];
  private total: MarketPositionTotal | null = null;
  private selectedPosition: MarketPosition | null = null;
  private readonly liveUpdates: LiveUpdates;
  private readonly oandaUpdates: OandaUpdates;
  private readonly positionHandler: PositionHandler;
  private readonly balanceHandler: BalanceHandler;
  private readonly ccxtPrivate: CcxtPrivate;

  private showHuf = false;
  private loading = true;
  private errorMsg = "";

  icons = {
    mdiAlert: mdiAlert,
  };

  constructor() {
    super();
    this.ccxtPrivate = new CcxtPrivate("binance");
    this.liveUpdates = new LiveUpdates();
    this.positionHandler = new PositionHandler(this.ccxtPrivate);
    this.balanceHandler = new BalanceHandler(this.ccxtPrivate);
    this.oandaUpdates = new OandaUpdates();
  }

  created() {
    this.getData().then();
    this.oandaUpdates.init();
    this.oandaUpdates.streamPrice(["USD_HUF"], this.oandaListener);
  }

  destroyed() {
    this.liveUpdates.destroy();
    this.oandaUpdates.destroy();
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  private async getData() {
    const tickers$ = this.ccxtPrivate.fetchTickers();

    const symbols = ["SOL/USDT", "LUNA/USDT", "LUNC/BUSD", "SAND/USDT", "AXS/USDT"];
    const dateFrom = "2022-05-01";
    const marketIds$ = lastValueFrom(marketApi.getAppMarketGetmarketids());
    const markets$ = this.positionHandler.getMarkets(symbols, dateFrom, tickers$);
    const balances$ = this.balanceHandler.getBalances(tickers$);

    let markets, balances;
    try {
      [markets, balances] = await Promise.all([markets$, balances$]);
    } catch (e) {
      this.errorMsg += e as string;
      return;
    }

    const orderFnc = (balance: Balance) => (balance.usdValue.value !== undefined ? balance.usdValue.value : 0);
    this.balances = orderBy(balances, [orderFnc], ["desc"]);

    const positions = await this.positionHandler.createPositions(markets || []);
    this.positions = orderBy(positions, ["totalCost"], ["desc"]);

    this.total = new MarketPositionTotal(this.positions, this.balances);
    if (this.positions.length) {
      this.setPosition(this.positions[0]);
    }

    await this.initLiveUpdates(marketIds$);
    this.loading = false;
  }

  private async initLiveUpdates(marketIds$: Promise<any>) {
    this.liveUpdates.reset();

    const marketIds = await marketIds$;
    const binanceExchangeId = 1;
    const binanceMarketIds = marketIds[binanceExchangeId];

    let allSymbols = [];
    for (const position of this.positions) {
      allSymbols.push(position.symbol);
    }
    for (const balance of this.balances) {
      if (balance.currency !== "USDT") {
        allSymbols.push(balance.usdSymbol);
      }
    }
    allSymbols = [...new Set(allSymbols)];

    for (const symbol of allSymbols) {
      const marketId = binanceMarketIds[symbol];
      if (!marketId) {
        continue;
      }

      const room = "ohlcv@" + marketId + "_1m";
      this.liveUpdates.addRoom(room, this.socketListener(symbol));
    }

    this.liveUpdates.join();
  }

  private oandaListener(instrument: string, price: number) {
    let [baseCurrency] = instrument.split("_");
    if (baseCurrency === "USD") {
      baseCurrency = "USDT";
    }
    for (const position of this.positions) {
      if (position.quoteCurrency !== baseCurrency) {
        continue;
      }

      this.positionHandler.updateRateHuf(position, price);
    }
    this.total?.calculateHufPrices();
    this.balanceHandler.updateRateHuf(price);
  }

  private socketListener(symbol: string) {
    return (candle: OhlcvCandle) => {
      for (const position of this.positions) {
        if (position.symbol !== symbol || position.priceChange.enabled) {
          continue;
        }

        this.positionHandler.calculatePosition(position, candle.close);
      }
      this.total?.calculatePrices();
      this.balanceHandler.updateRate(symbol, candle.close);
    };
  }

  private setPosition(position: MarketPosition) {
    this.selectedPosition = position;
  }
}
