
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PriceColored from "@/components/Balance/PriceColored.vue";

@Component({
  components: { PriceColored },
})
export default class PriceWithHuf extends Vue {
  @Prop()
  price?: number;

  @Prop()
  pricePercent?: number;

  @Prop()
  currency?: string;

  @Prop()
  hufPrice?: number;

  @Prop()
  showHuf?: boolean;

  @Prop({ default: false })
  noColor?: boolean;
}
