
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MarketPosition } from "@/components/Balance/positionHandler";
import CryptoiconCombined from "@/components/General/CryptoiconCombined.vue";
import PriceWithHuf from "@/components/Balance/PriceWithHuf.vue";
import PriceColored from "@/components/Balance/PriceColored.vue";
import { mdiChevronDown } from "@mdi/js";
import OrderListMobile from "@/components/Balance/OrderListMobile.vue";

@Component({
  components: { OrderListMobile, PriceColored, PriceWithHuf, CryptoiconCombined },
})
export default class PositionDetailsMobile extends Vue {
  @Prop()
  position?: MarketPosition;

  @Prop()
  showHuf?: boolean;

  showOrders = false;

  icons = {
    mdiChevronDown: mdiChevronDown,
  };
}
