
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MarketPosition } from "@/components/Balance/positionHandler";
import CryptoiconCombined from "@/components/General/CryptoiconCombined.vue";
import PriceWithHuf from "@/components/Balance/PriceWithHuf.vue";
import PositionDetailsMobile from "@/components/Balance/PositionDetailsMobile.vue";

@Component({
  components: { PositionDetailsMobile, PriceWithHuf, CryptoiconCombined },
})
export default class PositionList extends Vue {
  @Prop()
  positions?: MarketPosition[];

  @Prop()
  showHuf?: boolean;

  selectedPosition: MarketPosition | null = null;
  dialog = false;

  onPositionClicked(position: MarketPosition) {
    this.dialog = true;
    this.selectedPosition = position;
  }

  onDialogClick() {
    this.dialog = false;
    this.selectedPosition = null;
  }
}
