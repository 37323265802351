
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Price } from "@/components/Balance/marketPositionTotal";

@Component
export default class PriceColored2 extends Vue {
  @Prop()
  price?: Price;

  @Prop({ default: false })
  hideCurrency?: boolean;

  @Prop({ default: false })
  isPercent?: boolean;

  priceNotChanged = "";
  priceChanged = "";

  preCurrency = "";
  postCurrency = "";

  color = "inherit";
  timeout?: number;

  @Watch("price.value", { immediate: true })
  onPriceChange(value: number, oldValue?: number) {
    // Color
    this.color = "inherit";
    if (oldValue !== undefined) {
      this.color = value > oldValue ? "rgb(3, 166, 109)" : value < oldValue ? "red" : "inherit";
    }

    // Price
    this.diff(value, oldValue);

    // Currency
    const price = this.price;
    this.preCurrency = "";
    this.postCurrency = "";
    if (!this.hideCurrency && price && price.value !== undefined) {
      if (price.currency === "USD") {
        this.preCurrency = "$";
      } else {
        this.postCurrency = this.isPercent ? "%" : " " + price.currency;
      }
    }
    if (this.preCurrency !== "" && value < 0) {
      if (this.priceNotChanged.startsWith("-")) {
        this.priceNotChanged = this.priceNotChanged.substring(1);
      } else if (this.priceChanged.startsWith("-")) {
        this.priceChanged = this.priceChanged.substring(1);
      }
      this.preCurrency = "-" + this.preCurrency;
    }

    // Color timeout
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    this.timeout = setTimeout(() => {
      this.color = "inherit";
    }, 500);
  }

  private diff(value?: number, oldValue?: number) {
    if (oldValue === undefined || value === undefined) {
      this.priceNotChanged = value !== undefined ? this.price?.format() ?? "" : "";
      this.priceChanged = "";
      return;
    }

    const oldValueFormatted = this.price?.format(oldValue) ?? "";
    const valueFormatted = this.price?.format() ?? "";
    this.priceNotChanged = "";
    this.priceChanged = "";
    const oldValueChars = oldValueFormatted.split("");
    const valueChars = valueFormatted.split("");
    let pos = 0;
    for (const [index, char] of valueChars.entries()) {
      if (char !== oldValueChars[index]) {
        pos = index;
        break;
      }
      pos = index + 1;
    }
    this.priceNotChanged = valueFormatted.substring(0, pos);
    this.priceChanged = valueFormatted.substring(pos);
  }
}
